<template>
	<div>
		<div
			style="
				margin-top: 50px;
				background-color: rgb(32, 156, 238);
				padding: 35px;
			"
		>
			<b-table :data="dataSummary" :columns="columns" bordered stripped>
			</b-table>
		</div>
		<div
			style="
				margin-top: 50px;
				background-color: rgb(219, 225, 235);
				padding: 35px;
			"
		>
			<b-table :data="data" :columns="columns" bordered stripped> </b-table>
		</div>
	</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from '@vue/composition-api'
import numeral from 'numeral'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import dateformat from 'dateformat'

// * queries -----------------------------------------------------------
import GetDoubtfulReceivablesQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetDoubtfulReceivablesQuery.graphql'
import GetReceivablesAtKronofogdenQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetReceivablesAtKronofogdenQuery.graphql'
import GetReceivablesInInkassoQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetReceivablesInInkassoQuery.graphql'
import GetReceivablesReminderSentQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetReceivablesReminderSentQuery.graphql'
import GetReceivablesOverDueNotRemindedQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetReceivablesOverDueNotRemindedQuery.graphql'
import GetReceivablesNotDueQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetReceivablesNotDueQuery.graphql'
import GetTotalReceivablesQuery from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/graphql/GetTotalReceivablesQuery.graphql'

export default {
	name: 'NetPresentValuesComponent',
	setup() {
		const doubtfulReceivables = ref(0)
		const receivablesAtKronofogden = ref(0)
		const receivablesInInkasso = ref(0)
		const receivablesWithAReminderSent = ref(0)
		const otherOverdueReceivables = ref(0)
		const receivablesNotYetDue = ref(0)
		const totalReceivables = ref(0)
		const overdueDebts = ref(0)
		const debtsNotYetDue = ref(0)
		const totalDebts = ref(0)
		const currentWarehouseStock = ref(0)
		const netPresentValue = ref(0)
		// todo ----------------------------------------------------
		const tableValues = ref([])
		const data = computed(() => tableValues.value)
		const tableSummaryValues = ref([])
		const dataSummary = computed(() => tableSummaryValues.value)

		const formatNumberWithCurrency = (number, currency = 'kr.') => {
			return numeral(number / 100).format('0,0.00') + ' ' + currency
		}
		const getAmountReceivables = (item) => {
			let invoice =
				item.invoice_totals_aggregate.aggregate.sum.line_price_total_credit +
				item.invoice_totals_aggregate.aggregate.sum.vat_credit
			let payment = item.invoice_payments_aggregate.aggregate.sum.payment_credit
			let balance = invoice - payment
			return balance
		}
		const columns = [
			{
				field: 'id',
				label: 'ID',
				numeric: true,
			},
			{
				field: 'type',
				label: 'Credit Debit Type',
				numeric: true,
			},
			{
				field: 'amount',
				label: 'Amount',
				width: '200',
				numeric: true,
			},
			{
				field: 'exc',
				label: '',
			},
		]
		const getTableValues = () => {
			tableValues.value = []
			tableValues.value.push(
				{
					id: 1,
					type: 'Doubtful Receivables',
					amount: formatNumberWithCurrency(doubtfulReceivables.value),
				},
				{
					id: 2,
					type: 'Receivables at Kronofogden',
					amount: formatNumberWithCurrency(receivablesAtKronofogden.value),
				},
				{
					id: 3,
					type: 'Receivables in Inkasso',
					amount: formatNumberWithCurrency(receivablesInInkasso.value),
				},
				{
					id: 4,
					type: 'Receivables with a reminder sent',
					amount: formatNumberWithCurrency(receivablesWithAReminderSent.value),
				},
				{
					id: 5,
					type: 'Other overdue receivables',
					amount: formatNumberWithCurrency(otherOverdueReceivables.value),
				},
				{
					id: 6,
					type: 'Receivables not yet due',
					amount: formatNumberWithCurrency(receivablesNotYetDue.value),
				},
				{
					id: 7,
					type: 'Total receivables (excluding doubtful receivables)',
					amount: formatNumberWithCurrency(totalReceivables.value),
				},
				{
					id: 8,
					type: 'Overdue debts (Our Debts to Suppliers)',
					amount: formatNumberWithCurrency(overdueDebts.value),
				},
				{
					id: 9,
					type: 'Debts not yet due (Our Debts to Suppliers)',
					amount: formatNumberWithCurrency(debtsNotYetDue.value),
				},
				{
					id: 10,
					type: 'Total debts (Our Debts to Suppliers)',
					amount: formatNumberWithCurrency(totalDebts.value),
				},
				{
					id: 11,
					type: 'Current warehouse stock (not up to date)',
					amount: formatNumberWithCurrency(currentWarehouseStock.value),
				},
				{
					id: 12,
					type: 'NPV: Warehouse + total receivables - total debts (Note: Doubtful receivables are not included)',
					amount: formatNumberWithCurrency(netPresentValue.value),
				},
			)
		}
		const getTableSummaryValues = () => {
			tableSummaryValues.value = []
			tableSummaryValues.value.push(
				{
					id: 7,
					type: 'Total receivables (excluding doubtful receivables)',
					amount: formatNumberWithCurrency(totalReceivables.value),
				},
				{
					id: 10,
					type: 'Total debts (Our Debts to Suppliers)',
					amount: formatNumberWithCurrency(totalDebts.value),
				},
				{
					id: 11,
					type: 'Current warehouse stock (not up to date)',
					amount: formatNumberWithCurrency(currentWarehouseStock.value),
				},
				{
					id: 12,
					type: 'NPV: Warehouse + total receivables - total debts (Note: Doubtful receivables are not included)',
					amount: formatNumberWithCurrency(netPresentValue.value),
				},
			)
		}
		// *** ----------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult: onResultReceivable, refetch: refetchReceivable } =
			useQuery(
				GetDoubtfulReceivablesQuery,
				() => ({
					our_company: Store.getters.getUserCurrentCompany,
				}),
				options,
			)
		onResultReceivable((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			doubtfulReceivables.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		const { onResult: onResultAtKronofogden, refetch: refetchAtKronofogden } =
			useQuery(
				GetReceivablesAtKronofogdenQuery,
				() => ({
					our_company: Store.getters.getUserCurrentCompany,
				}),
				options,
			)
		onResultAtKronofogden((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			receivablesAtKronofogden.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		const { onResult: onResultInInkasso, refetch: refetchInInkasso } = useQuery(
			GetReceivablesInInkassoQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		onResultInInkasso((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			receivablesInInkasso.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		const { onResult: onResultReminded, refetch: refetchReminded } = useQuery(
			GetReceivablesReminderSentQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		onResultReminded((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			receivablesWithAReminderSent.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		// *** ------------------------------------------------------------------------
		// ? Function to get today's date in the full Date string format
		function getToday() {
			const today = ref(new Date())
			dateformat(today.value, 'yyyy-mm-dd')
			return today // Returns today's date in the full date string format
		}
		// *** ------------------------------------------------------------------------
		// ? ------------------------------------------------------------------------
		const { onResult: onResultNotReminded, refetch: refetchNotReminded } =
			useQuery(
				GetReceivablesOverDueNotRemindedQuery,
				() => ({
					our_company: Store.getters.getUserCurrentCompany,
					invoice_due_date: getToday(),
				}),
				options,
			)
		onResultNotReminded((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			otherOverdueReceivables.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		const { onResult: onResultNotDue, refetch: refetchNotDue } = useQuery(
			GetReceivablesNotDueQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				invoice_due_date: getToday(),
			}),
			options,
		)
		onResultNotDue((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			receivablesNotYetDue.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		const { onResult: onResultAllReceivables, refetch: refetchAllReceivables } =
			useQuery(
				GetTotalReceivablesQuery,
				() => ({
					our_company: Store.getters.getUserCurrentCompany,
				}),
				options,
			)
		onResultAllReceivables((result) => {
			const tempArray = ref([])
			tempArray.value = result?.data?.document_transactions
			const arr = result?.data?.document_transactions.map((item) => {
				return getAmountReceivables(item)
			})
			totalReceivables.value = arr.reduce((a, b) => a + b, 0)
		})
		// ? ------------------------------------------------------------------------
		onMounted(() => {
			refetchReceivable().then(() => {
				refetchAtKronofogden().then(() => {
					refetchInInkasso().then(() => {
						refetchReminded().then(() => {
							refetchNotReminded().then(() => {
								refetchNotDue().then(() => {
									refetchAllReceivables().then(() => {
										getTableValues()
										getTableSummaryValues()
									})
								})
							})
						})
					})
				})
			})
		})
		const selected = ref(data.value[1])
		return {
			data,
			columns,
			dataSummary,
			selected,
			getAmountReceivables,
		}
	},
}
</script>

<style scoped></style>
