<!-- @format -->

<template>
	<layout-default-new>
		<sub-navbar page="Admin" />
		<section
			style="
				background-color: rgb(219, 225, 235);
				padding-left: 15px;
				padding-top: 30px;
				padding-bottom: 20px;
				padding-right: 15px;
			"
		>
			<div class="form-title">Time Interval Revenue Values ({{ tag }})</div>
			<b-table
				:data="listArr"
				:columns="columns"
				focusable
				bordered
				striped
			></b-table>
			<b-field class="margin-top-20" label="Select beginning date">
				<b-datepicker
					v-model="selectedBeginningDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<b-field label="Select ending date">
				<b-datepicker
					v-model="selectedEndingDate"
					:show-week-number="showWeekNumber"
					:locale="locale"
					placeholder="Click to select..."
					icon="calendar-today"
					trap-focus
				>
				</b-datepicker>
			</b-field>
			<div style="margin-top: 10px">
				<b-button
					class="width-250"
					style="background-color: rgb(52, 136, 206); color: white"
					@click="getTimeIntervalResults"
				>
					Time Interval Results
				</b-button>
				<b-button
					class="margin-left-15 width-250"
					style="background-color: rgb(109, 65, 161); color: white"
					@click="getTodayResults"
				>
					Today's Results
				</b-button>
				<b-button
					class="margin-left-15 width-250"
					style="background-color: rgb(182, 90, 92); color: white"
					@click="getCurrentMonthsResults"
				>
					Get Current Month's Results
				</b-button>
				<b-button
					class="margin-left-15 width-250"
					style="background-color: rgb(61, 196, 134); color: white"
					@click="getLastTwelveMonthsResults"
				>
					Last Twelve Months's Results
				</b-button>
			</div>
		</section>
		<div>
			<NetPresentValuesComponent />
		</div>
	</layout-default-new>
</template>

<script>
import {
	computed,
	onMounted,
	reactive,
	ref,
	watchEffect,
} from '@vue/composition-api'
import dateformat from 'dateformat'
import Store from '@/store'
import { useQuery, useResult } from '@vue/apollo-composable'
import GetOrdersByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getOrdersByTimeInterval.query.gql'
import GetDispatchesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getDispatchesByTimeInterval.query.gql'
import GetDispatchesByDeliveryTimeInterval from '@/_srcv2/graphql/admin-reports/queries/GetDispatchesByDeliveryTimeInterval.graphql'
import GetInvoicesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getInvoicesByTimeInterval.query.gql'
import GetUninvoicedDispatchesByTimeInterval from '@/_srcv2/graphql/admin-reports/queries/getUninvoicedDispatchesByTimeInterval.query.gql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import NetPresentValuesComponent from '@/_srcv2/views/pages/admin-reports/time-interval-revenue/components/NetPresentValuesComponent.vue'

export default {
	name: 'TimeIntervalRevenue',
	components: {
		SubNavbar,
		NetPresentValuesComponent,
	},
	setup() {
		const tag = ref('The Current Month')
		const parameters = reactive({
			beginningDate: '',
			endingDate: '',
		})
		// todo ----------------------------------------------------------------
		// ? Function to get the first day of the current month in the full Date string format
		function getFirstDayOfCurrentMonth() {
			const today = new Date()
			const year = today.getFullYear()
			const month = today.getMonth() // Month is 0-indexed so no need to add 1
			const firstDay = new Date(year, month, 1) // Creates a new Date object with the first day of the month
			return firstDay // Returns the full date string format
		}

		// ? Function to get today's date in the full Date string format
		function getToday() {
			const today = new Date()
			return today // Returns today's date in the full date string format
		}

		// Example usage:
		// const bd = getFirstDayOfCurrentMonth()
		// const ed = getToday()

		// console.log('**** ed today', ed)
		// todo ----------------------------------------------------------------
		// ? Function to get the first day of the month 13 months ago
		function getFirstDayOf12MonthsAgo() {
			const today = new Date()
			const year = today.getFullYear()
			const month = today.getMonth() // Get the current month (0-indexed)

			// ? Calculate 12 months ago
			const date12MonthsAgo = new Date(year, month - 12, 1) // Month -12 gives us 12 months ago
			return date12MonthsAgo // Return in full Date string format
		}

		// ? Function to get the last day of the month before the current month
		function getLastDayOfPreviousMonth() {
			const today = new Date()
			const year = today.getFullYear()
			const month = today.getMonth() // Get the current month (0-indexed)

			// ? Create a date object for the last day of the previous month
			const lastDayOfPreviousMonth = new Date(year, month, 0) // Month 0 gives the last day of the previous month
			return lastDayOfPreviousMonth // Return in full Date string format
		}

		// Example usage:
		// const bdLastTwelveMonths = getFirstDayOf12MonthsAgo() // First day of the month from 13 months ago
		// const edLastTwelveMonths = getLastDayOfPreviousMonth() // Last day of the previous month

		// console.log(
		// 	'bdLastTwelveMonths',
		// 	bdLastTwelveMonths,
		// 	'edLastTwelveMonths',
		// 	edLastTwelveMonths,
		// )

		const condition = true
		const fetchDates = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					selectedBeginningDate.value = getFirstDayOfCurrentMonth() // today.value
					selectedEndingDate.value = getToday()
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const fetchToday = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					selectedBeginningDate.value = getToday() // today.value
					selectedEndingDate.value = getToday()
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const fetchLastTwelveMonths = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					selectedBeginningDate.value = getFirstDayOf12MonthsAgo() // today.value
					selectedEndingDate.value = getLastDayOfPreviousMonth()
					resolve('ok')
				} else {
					const reason = new Error('Today is null')
					reject(reason)
				}
			})
		}
		const getTodayResults = () => {
			fetchToday().then(() =>
				setTimeout(() => {
					getData()
					tag.value = "Today's Results"
				}, 200),
			)
		}
		const getCurrentMonthsResults = () => {
			fetchDates()
			setTimeout(() => {
				getData()
				tag.value = "Current Month's Results"
			}, 100)
		}
		const getLastTwelveMonthsResults = () => {
			fetchLastTwelveMonths().then(() =>
				setTimeout(() => {
					getData()
					tag.value = "Last Twelve Month's Results"
				}, 200),
			)
		}
		const getTimeIntervalResults = () => {
			tag.value = `Between ${parameters.beginningDate} and ${parameters.endingDate}`
			getData()
		}
		const selectedBeginningDate = ref(new Date())
		watchEffect(() => {
			parameters.beginningDate = dateformat(
				selectedBeginningDate.value,
				'yyyy-mm-dd',
			)
		})
		const selectedEndingDate = ref(new Date())
		watchEffect(() => {
			parameters.endingDate = dateformat(selectedEndingDate.value, 'yyyy-mm-dd')
		})
		const showWeekNumber = false
		const locale = 'sv-SE'
		// Time Interval Orders
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result: resultOrder, refetch: refetchOrder } = useQuery(
			GetOrdersByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedOrders = useResult(
			resultOrder,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)

		// ? Time Interval Dispatches
		const { result: resultDispatch, refetch: refetchDispatch } = useQuery(
			GetDispatchesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedDispatches = useResult(
			resultDispatch,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		// todo Delivery Time Interval Dispatches
		const { result: resultDeliveryDispatch, refetch: refetchDeliveryDispatch } =
			useQuery(
				GetDispatchesByDeliveryTimeInterval,
				() => ({
					company_id: Store.getters.getUserCurrentCompany,
					bd: parameters.beginningDate,
					ed: parameters.endingDate,
				}),
				options,
			)
		const searchedDeliveryDispatches = useResult(
			resultDeliveryDispatch,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		// todo Time Interval Invoices
		const { result: resultInvoice, refetch: refetchInvoice } = useQuery(
			GetInvoicesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedInvoices = useResult(
			resultInvoice,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		// ? Time Interval Uninvoiced Dispatches
		const {
			result: resultUninvoicedDispatches,
			refetch: refetchUninvoicedDispatches,
		} = useQuery(
			GetUninvoicedDispatchesByTimeInterval,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		const searchedUninvoicedDispatches = useResult(
			resultUninvoicedDispatches,
			null,
			(data) => data.goods_transactions_aggregate.aggregate.sum,
		)
		let tableValues = ref([])
		const getTableValues = () => {
			tableValues.value = []
			tableValues.value.push({
				type: 'Delivered Dispatch',
				revenue: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.vat_credit,
				),
				total: floatedCurrencyAddedNumber(
					searchedDeliveryDispatches.value.line_price_total_credit +
						searchedDeliveryDispatches.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Order',
				revenue: floatedCurrencyAddedNumber(
					searchedOrders.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedOrders.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedOrders.value.line_price_total_credit +
						searchedOrders.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Dispatch',
				revenue: floatedCurrencyAddedNumber(
					searchedDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedDispatches.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedDispatches.value.line_price_total_credit +
						searchedDispatches.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Invoice',
				revenue: floatedCurrencyAddedNumber(
					searchedInvoices.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(searchedInvoices.value.vat_credit),
				total: floatedCurrencyAddedNumber(
					searchedInvoices.value.line_price_total_credit +
						searchedInvoices.value.vat_credit,
				),
			})
			tableValues.value.push({
				type: 'Uninvoiced Dispatches',
				revenue: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.line_price_total_credit,
				),
				vat: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.vat_credit,
				),
				total: floatedCurrencyAddedNumber(
					searchedUninvoicedDispatches.value.line_price_total_credit +
						searchedUninvoicedDispatches.value.vat_credit,
				),
			})
		}
		const getData = () => {
			refetchOrder()
				.then(() => refetchDispatch())
				.then(() => refetchDeliveryDispatch())
				.then(() => refetchInvoice())
				.then(() => refetchUninvoicedDispatches())
				.then(() => {
					getTableValues()
				})
		}
		const listArr = computed(() => tableValues.value)
		const columns = [
			{
				field: 'type',
				label: 'Document Type',
				width: '170',
			},
			{
				field: 'revenue',
				label: 'Revenue',
				width: '150',
				numeric: true,
			},
			{
				field: 'vat',
				label: 'VAT',
				width: '150',
				numeric: true,
			},
			{
				field: 'total',
				label: 'Total',
				width: '150',
				numeric: true,
			},
		]
		const { floatedCurrencyAddedNumber } = useReportNumber()

		onMounted(() => {
			getCurrentMonthsResults()
		})
		return {
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			getData,
			listArr,
			columns,
			getTodayResults,
			getCurrentMonthsResults,
			getLastTwelveMonthsResults,
			getTimeIntervalResults,
			tag,
		}
	},
}
</script>

<style scoped>
.loading-text {
	margin-top: 30px;
	color: red;
	font-size: xx-large;
	font-weight: bolder;
}
.form-title {
	font-weight: bolder;
	font-size: 1.5em;
	color: #9b1c1c;
	margin-left: 5px;
	margin-bottom: 20px;
}
.form-btn {
	margin-left: 20px;
}
.margin-top-20 {
	margin-top: 20px;
}
.alarm {
	color: red;
}
.green {
	color: green;
}
.blue {
	color: blue;
}
</style>
